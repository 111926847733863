import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';


class EditPage extends React.Component {

	render() {
		return (
			<Layout>
				<SEO
					title="The Master List (Edit Mode)"
					titleNoPostfix={true}
				/>
				<div className="embed-iframe-container edit edit-enabled">
					<iframe title="Edit The Master List" className="embed-frame edit" src="https://docs.google.com/spreadsheets/d/1OdD5r4o7McgLH_Qj2S_cDvD9Z0RUDZybVEt98uF990s/edit#gid=1756437520"></iframe>
				</div>
				<div className="page-content loading-page edit-disabled">
					<h3>Taking you to Google Sheets...</h3>
				</div>
			</Layout>
		);
	}
}

export default EditPage;
